import React, { useEffect, useRef, useState } from "react";
import EntryPage from "../components/EntryPage";
import "../styles/pages/homepage.scss";
import gsap from "gsap";
import { graphql } from "gatsby";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { WhoSection, ArtSection, WorkSection } from "../components/homepage";
import { VideoPlayer } from "../components/Video/";
import ReactPlayer from "react-player";
import Headline from "../components/Headline";
import CTA from "../components/Button";
import Previews from "../components/homepage/Previews";
import { useCheckScreenSize } from "../lib/checkScreenSize";
import { StaticImage } from "gatsby-plugin-image";
gsap.registerPlugin(ScrollTrigger);

export const Home = ({ data }) => {
  const videoWrapperRef = useRef(null);
  const heroImageRef = useRef(null);
  const bottomImageRef = useRef(null);
  const isMobileLarge = useCheckScreenSize(900);
  const [videoEnded, setVideoEnded] = useState(false);
  const showReelUrl = "https://youtu.be/eO5x4XkHzk8?si=0wNbu0vVhd1CU2pg";

  console.log("isMobileLarge", isMobileLarge);

  const {
    carousel: { edges: carouselImages },
    narratives: { edges: narrativesImages },
    brands: { edges: brandsImages },
    musicVideos: { edges: musicVideosImages },
    previewData: { edges: previews },
  } = data;

  const whoImages = assetArrayToObject({
    useGatsbyImage: true,
    images: carouselImages,
  });

  const narrativeImages = assetArrayToObject({
    useGatsbyImage: true,
    images: narrativesImages,
  });
  const brandImages = assetArrayToObject({
    useGatsbyImage: true,
    images: brandsImages,
  });
  const musicVideoImages = assetArrayToObject({
    useGatsbyImage: true,
    images: musicVideosImages,
  });

  useEffect(() => {
    if (videoWrapperRef) {
      gsap.set(videoWrapperRef.current, { y: -400 });
      gsap.fromTo(
        videoWrapperRef.current,
        { y: -400 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".rc-homepage__hero",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (heroImageRef) {
      gsap.set(heroImageRef.current, { y: -200 });
      gsap.fromTo(
        heroImageRef.current,
        { y: -200 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".rc-homepage__hero",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (bottomImageRef) {
      gsap.set(bottomImageRef.current, { y: -200 });
      gsap.fromTo(
        bottomImageRef.current,
        { y: -200 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".rc-homepage__bottom-image",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const structuredPreviews = () => {
    return {
      comingSoon: previews.filter((preview) => preview.node.comingSoon),
      nowPlaying: previews.filter((preview) => preview.node.nowPlaying),
    };
  };

  const decoupledPreviews = structuredPreviews();

  return (
    <div className="rc-homepage">
      <EntryPage onVideoEnd={() => setVideoEnded(true)} />
      {!videoEnded && <span className="rc-homepage__placeholder"></span>}
      {videoEnded && (
        <>
          <section className="rc-homepage__hero">
            <div className="rc-homepage__hero__logo">
              <div className="rc-homepage__hero__logo__wrapper">
                <StaticImage
                  src="../images/rawColorLogoR.png"
                  alt="Raw Color logo"
                  placeholder="blurred"
                  layout="fullWidth"
                  loading="lazy"
                  quality={70}
                />
              </div>
              <h4>Scroll to explore</h4>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="var(--white)"
                    d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8l316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496"
                  />
                </svg>
              </span>
            </div>
            <div
              ref={videoWrapperRef}
              className="rc-homepage__hero__video-wrapper"
            >
              <video
                playsInline
                loading="lazy"
                loop
                autoPlay
                muted
                preload="metadata"
              >
                <source
                  src="https://s3.us-west-1.amazonaws.com/assets.rawcolorstudios.com/rawcolorHeroReel.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </section>
          <Previews previews={decoupledPreviews} />
          <WhoSection images={whoImages} />
          <section className="rc-homepage__show-reel">
            <div className="rc-homepage__show-reel__inner">
              <h1 id="rc-showreel-header">
                <Headline
                  triggerId={"rc-showreel-header"}
                  text={"PRODUCTION REEL"}
                />
              </h1>
              <div className="rc-homepage__show-reel__mobile">
                <ReactPlayer
                  url={showReelUrl}
                  controls={true}
                  width="100%"
                  height="100%"
                  className={`w-full h-full cursor-pointer`}
                />
              </div>
              {!isMobileLarge && (
                <VideoPlayer
                  controls={false}
                  url="https://youtu.be/eO5x4XkHzk8?si=jC3m6Ka0VL3NsHYT"
                />
              )}
            </div>
          </section>
          <ArtSection />
          <WorkSection
            brandImages={brandImages}
            narrativeImages={narrativeImages}
            musicVideoImages={musicVideoImages}
          />
          <section className="rc-homepage__contact">
            <div className="rc-homepage__contact__inner">
              <h1>Get in touch.</h1>
              <p>
                Looking to collaborate on a project? We'd love to hear from you.
              </p>
              <div className="rc-homepage__contact__button">
                <CTA to="/contact" theme="hollow">
                  Contact
                </CTA>
              </div>
            </div>
          </section>
          <div className="rc-homepage__bottom-image">
            <div
              ref={bottomImageRef}
              className="rc-homepage__bottom-image__wrapper"
            >
              <StaticImage
                src="../images/FishermanStill7.png"
                alt="Fisherman Beach"
                placeholder="blurred"
                layout="fullWidth"
                loading="lazy"
                quality={70}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const query = graphql`
  query {
    previewData: allContentfulWorkPost(
      filter: { live: { eq: true } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          coverImage {
            gatsbyImageData(
              width: 580
              quality: 70
              placeholder: DOMINANT_COLOR
            )
          }
          title
          contentful_id
          live
          comingSoon
          nowPlaying
        }
      }
    }

    carousel: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "homepage/projectorLong" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 488, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }

    narratives: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: "homepage/narratives" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    brands: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: "homepage/brands" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    musicVideos: allFile(
      filter: {
        extension: { eq: "png" }
        relativeDirectory: { eq: "homepage/musicVideos" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;

export default React.memo(Home);
