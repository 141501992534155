import React, { useEffect, useRef } from "react";
import "./circular-text.scss";

const CircularText = ({ textArray, radius, className }) => {
  const circularTextRef = useRef(null);

  useEffect(() => {
    if (circularTextRef.current) {
      const angleStep = 360 / textArray.length;
      const curvedTextDiv = circularTextRef.current;
      curvedTextDiv.innerHTML = "";

      textArray.forEach((text, index) => {
        const span = document.createElement("span");
        span.textContent = text;
        const angle = angleStep * index;
        const transform = `rotate(${angle}deg) translate(${radius}px) rotate(${-angle}deg)`;
        span.style.transform = transform;
        span.style.position = "absolute";
        span.style.height = `${radius}px`;
        span.style.transformOrigin = "bottom center";

        curvedTextDiv.appendChild(span);
      });
    }
  }, [textArray, radius]);

  return (
    <div ref={circularTextRef} className={`circular-text ${className}`}></div>
  );
};

export default CircularText;
