import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import "./work.scss";
import gsap from "gsap";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "../../links";
import {
  narrativeImageData,
  brandImageData,
  musicVideoImageData,
} from "../../../data/pages/homepage";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
gsap.registerPlugin(ScrollTrigger);

const WorkSection = ({ narrativeImages, brandImages, musicVideoImages }) => {
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [hoverItem, setHoverItem] = useState(null);
  const cursorRef = useRef(null);
  const containerRef = useRef(null);
  const mobileImageRef = useRef(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const isMobileLarge = useCheckScreenSize(790);
  const isMobileSmall = useCheckScreenSize(500);
  const panelSet = isMobileLarge ? 1 : 2;
  useEffect(() => {
    if (!isMobileSmall) {
      document.addEventListener("mousemove", onMouseMove);
    }
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  useEffect(() => {}, [hoverItem]);

  useEffect(() => {
    if (!isMobileSmall) {
      const containerWidth = containerRef.current.offsetWidth;
      const panels1 = gsap.utils.toArray(slider1.current.children);
      const panels2 = gsap.utils.toArray(slider2.current.children);
      // const panels3 = gsap.utils.toArray(slider3.current.children);
      gsap.set(panels2, { x: -containerWidth / panelSet });
      let ctx = gsap.context(() => {
        gsap.to(panels1, {
          xPercent: -100 * (panels1.length - 2),
          ease: "none",
          scrollTrigger: {
            trigger: containerRef.current,
            pin: true,
            scrub: isMobileLarge ? 8 : 1,
            start: "top top",
            end: () => "+=" + containerRef.current.offsetWidth,
          },
        });
      }, [containerRef]);
      let ctx2 = gsap.context(() => {
        gsap.to(panels2, {
          xPercent: 100 * (panels2.length - 3),
          ease: "none",
          scrollTrigger: {
            trigger: containerRef.current,
            scrub: isMobileLarge ? 8 : 1,
            start: "top top",
            end: () => "+=" + containerRef.current.offsetWidth,
          },
        });
      }, [containerRef]);
      return () => {
        ctx.revert();
        ctx2.revert();
      };
    }

    if (isMobileSmall) {
      gsap.set(mobileImageRef.current, { y: -200 });
      gsap.fromTo(
        mobileImageRef.current,
        {
          y: -200,
        },
        {
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".work-section",
            start: "top bottom",
            end: "bottom top",
            scrub: true,
          },
        }
      );
    }
  }, []);

  const onMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
    cursorRef.current.style.top = `${e.clientY}px`;
    cursorRef.current.style.left = `${e.clientX}px`;
  };

  const onPanelHover = (workType) => {
    setHoverItem(workType);
    if (cursorRef.current) {
      cursorRef.current.style.opacity = "1";
    }
  };

  const onPanelOut = () => {
    setHoverItem(null);
    cursorRef.current.style.opacity = "0";
  };

  const cursorClasses = classNames("custom-cursor", {
    "custom-cursor--narratives": hoverItem === "Narratives",
    "custom-cursor--brands": hoverItem === "Brands",
    "custom-cursor--music-videos": hoverItem === "Music Videos",
  });

  return (
    <div className="work-section">
      <div className="work-section__inner">
        <div className="work-section__header">
          <h2>EXPLORE OUR WORK</h2>
        </div>
        {isMobileSmall && (
          <Link to="/work">
            <div
              ref={mobileImageRef}
              className="work-section__mobile-image__wrapper"
            >
              <StaticImage
                src="../../../images/wedding.jpg"
                alt="wedding"
                placeholder="blurred"
                layout="fullWidth"
                loading="lazy"
              />
            </div>
            <div className="work-section__mobile-image__text">
              <h2>Click to explore our work.</h2>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="var(--white)"
                    d="M11 6v4.59H8.71c-.45 0-.67.54-.35.85l3.29 3.29c.2.2.51.2.71 0l3.29-3.29a.5.5 0 0 0-.35-.85H13V6c0-.55-.45-1-1-1s-1 .45-1 1m-3.9 8c-.61 0-1.11.55-.99 1.15C6.65 17.91 9.08 20 12 20s5.35-2.09 5.89-4.85c.12-.6-.38-1.15-.99-1.15c-.49 0-.88.35-.98.83C15.53 16.64 13.93 18 12 18s-3.53-1.36-3.91-3.17c-.1-.48-.5-.83-.99-.83"
                  />
                </svg>
              </span>
            </div>
          </Link>
        )}
      </div>
      {!isMobileSmall && (
        <>
          <div ref={containerRef} className="work-section__container">
            <div ref={slider1} className="work-section__container__index">
              {narrativeImageData.map((image) => {
                const workType = "Narratives";
                return (
                  <div
                    key={image}
                    className="work-section__container__index__image panel"
                  >
                    <Link to="/work">
                      <GatsbyImage
                        image={narrativeImages[image]}
                        alt={`${image}-image`}
                        onMouseEnter={() => onPanelHover(workType)}
                        onMouseLeave={() => onPanelOut()}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
            <div ref={slider2} className="work-section__container__index">
              {brandImageData.map((image) => {
                const workType = "Brands";
                return (
                  <div
                    key={image}
                    className="work-section__container__index__image panel"
                  >
                    <Link to="/brands">
                      <GatsbyImage
                        image={brandImages[image]}
                        alt={`${image}-image`}
                        onMouseEnter={() => onPanelHover(workType)}
                        onMouseLeave={() => onPanelOut()}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
            {/* <div ref={slider3} className="work-section__container__index panel">
          {musicVideoImageData.map((image) => {
            const workType = "Music Videos";
            return (
              <div
                key={image}
                className="work-section__container__index__image"
              >
                <GatsbyImage
                  image={musicVideoImages[image]}
                  alt={`${image}-image`}
                  onMouseEnter={() => onPanelHover(workType)}
                  onMouseLeave={() => onPanelOut()}
                />
              </div>
            );
          })}
        </div> */}
          </div>
          <div ref={cursorRef} className={cursorClasses}>
            {hoverItem}
          </div>
        </>
      )}
    </div>
  );
};

export default WorkSection;
