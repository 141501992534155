import React, { useContext, useRef, useState, useEffect } from "react";
import "./volume-dial.scss";
import { StaticImage } from "gatsby-plugin-image";
import CircularText from "../../CircleText";
import { volumeConverter } from "../../../lib/volumeConverter";
import ShowReelContext from "../../../context/showreelContext";
import classNames from "classnames";

const VolumeDial = () => {
  const { setPlayerVolume } = useContext(ShowReelContext);
  const [rotation, setRotation] = useState(-18);
  const [isDragging, setIsDragging] = useState(false);
  const rotationRef = useRef(rotation);
  const dialRef = useRef(null);
  const numbers = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"];
  useEffect(() => {
    setPlayerVolume(volumeConverter(rotation));
  }, [rotation]);
  const handleMouseDown = (e) => {
    e.preventDefault();
    const origin = dialRef.current.getBoundingClientRect();
    const center = {
      x: origin.left + origin.width / 2,
      y: origin.top + origin.height / 2,
    };

    setIsDragging(true);

    const onMouseMove = (e) => {
      const angle =
        Math.atan2(e.clientY - center.y, e.clientX - center.x) *
        (180 / Math.PI);
      setRotation(angle);
      rotationRef.current = angle;
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", onMouseMove);
        setIsDragging(false);
      },
      { once: true }
    );
  };

  const dialClasses = classNames("rc-volume-dial", {
    "rc-volume-dial--dragging": isDragging,
  });
  return (
    <div onMouseDown={handleMouseDown} className={dialClasses}>
      <div className="rc-volume-dial__inner">
        <p>Volume</p>
        <div className="rc-volume-dial__layer-1">
          <div className="rc-volume-dial__layer-2">
            <div className="rc-volume-dial__circle-text">
              <CircularText textArray={numbers} radius={62} />
            </div>
            <div className="rc-volume-dial__layer-2-5">
              <div className="rc-volume-dial__layer-3">
                <div
                  style={{ transform: `rotate(${rotationRef.current}deg)` }}
                  ref={dialRef}
                  className="rc-volume-dial__layer-4"
                >
                  <div className="rc-volume-dial__layer-5">
                    <StaticImage
                      src="../../../images/homepage/centerDial.png"
                      alt="center-dial"
                      width={80}
                    />
                    <div className="rc-volume-dial__layer-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolumeDial;
