import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { whoImageData, whoCopy } from "../../../data/pages/homepage";
import gsap from "gsap";
import { colorizer } from "../../../lib/colorizer";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import projectorOverlay from "../../../images/homepage/projectorOverlay.mp4";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
import TextHighlighter from "../../TextHighlighter";
import "./who.scss";
gsap.registerPlugin(ScrollTrigger);

const WhoSection = ({ images }) => {
  const whoHeaderRef = useRef(null);
  const slideIndexRef = useRef(null);
  const videoOverlayRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobile = useCheckScreenSize(460);

  const parallaxAmount = isMobile ? -100 : -300;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide(
        (prevCurrentSlide) => (prevCurrentSlide + 1) % whoImageData.length
      );
      gsap.fromTo(
        videoOverlayRef.current,
        {
          opacity: "100%",
          ease: "power1.out",
        },
        {
          delay: 0.25,
          opacity: "30%",
          duration: 0.25,
        }
      );
    }, 3000);

    const whoTitleLetters = [...whoHeaderRef.current.children];

    whoTitleLetters.forEach((letter, index) => {
      gsap.fromTo(
        letter,
        {
          y: 200,
        },
        {
          y: 0,
          duration: 1,
          delay: index * 0.05,
          scrollTrigger: {
            trigger: whoHeaderRef.current,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    });

    gsap.fromTo(
      slideIndexRef.current,
      { y: parallaxAmount },
      {
        y: 0,
        scrollTrigger: {
          trigger: "rc-homepage__who",
          scrub: true,
          start: "top bottom",
          end: "bottom top",
        },
      }
    );

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      clearInterval(intervalId);
    };
  }, []);
  return (
    <section className="rc-homepage__who">
      <div ref={whoHeaderRef} className="rc-homepage__who__inner">
        <div className="rc-homepage__who__top">
          <div className="rc-homepage__who__title">
            <h2 id="rc-who-title-who">
              WHO
            </h2>
            <h2 id="rc-who-title-we">
              WE
            </h2>
            <h2 id="rc-who-title-are">
              ARE
            </h2>
          </div>
          <div className="rc-homepage__who__projector">
            <video
              playsInline
              ref={videoOverlayRef}
              loop
              autoPlay
              muted
              preload="metadata"
            >
              <source src={projectorOverlay} type="video/mp4" />
            </video>
            <div
              ref={slideIndexRef}
              className="rc-homepage__who__projector__index"
            >
              {whoImageData.map((image) => {
                const slideClasses = classNames(
                  "rc-homepage__who__projector__image",
                  {
                    "rc-homepage__who__projector__image--active":
                      image === whoImageData[currentSlide],
                  }
                );
                return (
                  <div key={image} className={slideClasses}>
                    <GatsbyImage alt="image" image={images[image]} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="rc-homepage__who__copy">
          {whoCopy.map((text, index) => {
            return (
              <div
                key={text.split(" ")[0]}
                className="rc-homepage__who__copy__box"
              >
                <h6>{`0${index + 1}`}</h6>
                <p><TextHighlighter text={text} italic={true} highlightPhrase={"Art Before All"} color="var(--gold)"/></p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default WhoSection;
