import React from "react";
import "./buttons.scss";
import classNames from "classnames";

const PlayerButtons = ({ pressed }) => {
  const pressedClasses = classNames("rc-player-buttons__layer-4", {
    "rc-player-buttons__layer-4--pressed": pressed,
  });
  return (
    <div className="rc-player-buttons">
      <div className="rc-player-buttons__inner">
        <div className="rc-player-buttons__layer-1">
          <div className="rc-player-buttons__layer-2">
            <div className="rc-player-buttons__layer-3">
              <div className={pressedClasses}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerButtons;
