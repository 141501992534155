export const volumeConverter = (rotation) => {
  // Normalize the rotation to a 0-360 degree range
  const normalizedRotation = (rotation + 360) % 360;

  // Adjusting the normalized rotation for the range from 57 to 56 (including wrap-around)
  let adjustedRotation;
  if (normalizedRotation > 56) {
    adjustedRotation = normalizedRotation - 360;
  } else {
    adjustedRotation = normalizedRotation;
  }

  // Calculate volume
  let volume;
  if (-303 <= adjustedRotation && adjustedRotation <= 56) {
    volume = ((adjustedRotation + 303) / 359) * 100;
  } else {
    volume = 0;
  }

  return (Math.round(volume) / 100);
}
