import React, { useState, useRef, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "../../links";
import classNames from "classnames";
import "./previews.scss";

const Previews = ({ previews }) => {
  const [isHoveringFilm, setIsHoveringFilm] = useState(false);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [hoverItem, setHoverItem] = useState(null);
  const [currentFilmHovered, setCurrentFilmHovered] = useState(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  useEffect(() => {}, [hoverItem]);

  const onMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
    cursorRef.current.style.top = `${e.clientY}px`;
    cursorRef.current.style.left = `${e.clientX}px`;
  };

  const onPanelHover = (title, element) => {
    setHoverItem(title);
    cursorRef.current.style.opacity = "1";
  };

  const onPanelOut = (element) => {
    setHoverItem(null);
    cursorRef.current.style.opacity = "0";
  };


  const overlayColorClasses = classNames("rc-previews__overlay", {
    "rc-previews__overlay__red": isHoveringFilm === 0,
    "rc-previews__overlay__gold": isHoveringFilm === 1,
    "rc-previews__overlay__green": isHoveringFilm === 2,
    "rc-previews__overlay__blue": isHoveringFilm === 3,
    "rc-previews__overlay__purple": isHoveringFilm === 4,
  });

  const innerClasses = classNames("rc-previews__inner", {
    "rc-previews__inner--single":
      previews.nowPlaying.length < 2 && previews.comingSoon.length < 2,
  });

  const nowPlayingClasses = classNames("rc-previews__now-playing", {
    "rc-previews__now-playing--single":
      previews.nowPlaying.length < 2 && previews.comingSoon.length < 2,
  });
  return (
    <section className="rc-previews">
      <div className={innerClasses}>
        {previews.nowPlaying.length > 0 && (
          <div className={nowPlayingClasses}>
            <h1>NOW PLAYING</h1>
            <div className="rc-previews__now-playing__index">
              {previews.nowPlaying.map((preview, index) => {
                const imageClasses = classNames(
                  "rc-previews__now-playing__index__image", {
                    "rc-previews__now-playing__index__image--unhovered": isHoveringFilm !== false && currentFilmHovered !== preview.node.title,
                  }
                );
                return (
                  <div
                    key={index}
                    className="rc-previews__now-playing__index__item"
                  >
                    <Link to={`/work/${preview.node.contentful_id}`}>
                      <div
                        onMouseLeave={() => {
                          setIsHoveringFilm(false);
                          setCurrentFilmHovered(null);
                          onPanelOut();
                        }}
                        onMouseOver={() => {
                          setIsHoveringFilm(index % 5);
                          setCurrentFilmHovered(preview.node.title);
                          onPanelHover(preview.node.title);
                        }}
                        className={imageClasses}
                      >
                        <GatsbyImage
                          image={preview.node.coverImage.gatsbyImageData}
                          alt={preview.node.title}
                        />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {previews.comingSoon.length > 0 && (
          <div className={nowPlayingClasses}>
            <h1>COMING SOON</h1>
            <div className="rc-previews__now-playing__index">
              {previews.comingSoon.map((preview, index) => {
                const imageClasses = classNames(
                  "rc-previews__now-playing__index__image",
                  {
                    "rc-previews__now-playing__index__image--unhovered":
                      isHoveringFilm !== false &&
                      currentFilmHovered !== preview.node.title,
                  }
                );
                return (
                  <div
                    key={index}
                    className="rc-previews__now-playing__index__item"
                  >
                    <Link to={`/work/${preview.node.contentful_id}`}>
                      <div
                        onMouseLeave={() => {
                          setIsHoveringFilm(false);
                          setCurrentFilmHovered(null);
                          onPanelOut();
                        }}
                        onMouseOver={() => {
                          setIsHoveringFilm(
                            (index + previews.nowPlaying.length) % 5
                          );
                          setCurrentFilmHovered(preview.node.title);
                          onPanelHover(preview.node.title);
                        }}
                        className={imageClasses}
                      >
                        <GatsbyImage
                          image={preview.node.coverImage.gatsbyImageData}
                          alt={preview.node.title}
                        />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <span className={overlayColorClasses}></span>
      <div ref={cursorRef} className="rc-previews__custom-cursor">
        <h4>{currentFilmHovered}</h4>
      </div>
    </section>
  );
};

export default Previews;
