import React from 'react';

const TextHighlighter = ({ text, highlightPhrase, color="var(--white)", italic=false }) => {

  const splitText = () => {
    const regex = new RegExp(`(${highlightPhrase})`, "gi");
    const parts = text.split(regex);

    return parts.map((part) => ({
      text: part,
      highlight: part.toLowerCase() === highlightPhrase.toLowerCase(),
    }));
  };

  const parts = splitText();

  const fontStyle = italic ? "italic" : "normal";

  return (
    <p>
      {parts.map((part, index) =>
        part.highlight ? (
          <span key={index} style={{ color: `${color}`, fontStyle: fontStyle }}>
            {part.text}
          </span>
        ) : (
          <span key={index}>{part.text}</span>
        )
      )}
    </p>
  );
};

export default TextHighlighter;
