export const whoCopy = [
  "We are an independent creative studio on a journey to redefine storytelling on our own terms and serve audiences yearning for something different.",
  "Our mission is to push artistic boundaries to showcase the world the way we see it today – as a generation raised by the internet – with truth and authenticity.",
  "Our guiding mantra at Raw Color is: Art Before All. We believe that great art has the power to change the world, and the pursuit of artistic excellence is at the forefront of every project we embark on.",
];

export const whoImageData = [
  "bts",
  "desertTree",
  "desert",
  // "sauce",
  "thinkers",
  // "tri",
  "deathValley",
  "pAndT",
  "studio",
  "skelly",
  "kitchen",
  // "triBeach",
];

export const narrativeImageData = [
  "comedyisfinished",
  "fisherman",
  "ladke",
  "midightbroadcast",
  "payasam",
  "pisforprema",
  "roadrage",
  "violetMind",
  "zealot",
];

export const brandImageData = ["popupshop", "4dmedical", "ugpc", "yerbamate"];

export const musicVideoImageData = ["dreauto", "krish", "wishUponAStar"];
