import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";
import "./entry-page.scss";
import { useCheckScreenSize } from "../../lib/checkScreenSize";

const EntryPage = ({ text, onVideoEnd }) => {
  const loadingContainerRef = useRef(null);
  const textRef = useRef(null);
  const videoRef = useRef(null);
  const isMobile = useCheckScreenSize(860);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2;
    }

    const tl = gsap.timeline({
      onComplete: () => onVideoEnd(),
    });

    const tl2 = gsap.timeline();

    tl.to(loadingContainerRef.current, {
      opacity: 0,
      zIndex: -1,
      duration: 1,
      delay: 6.4,
      ease: "power4.out",
    });
    tl2.to(textRef.current, {
      duration: 0.8,
      delay: 0.3,
      ease: "power4.out",
      opacity: 1,
    });
  }, [onVideoEnd]);

  return (
    <div className="entry-page" ref={loadingContainerRef}>
      <div className="entry-page__image">
        {!isMobile && (
          <video
            ref={videoRef}
            loading="eager"
            playbackRate={2}
            autoPlay
            muted
            onEnded={onVideoEnd}
            preload="auto"
            style={{ minHeight: "720px", width: "100%" }} // Pre-allocate space
          >
            <source
              src="https://s3.us-west-1.amazonaws.com/assets.rawcolorstudios.com/intro.mp4"
              type="video/mp4"
            />
          </video>
        )}
        {isMobile && (
          <>
            <StaticImage
              src="../../images/fishermanStillTablet.png"
              alt="Background Image"
              layout="fullWidth"
              quality={60}
              formats={["AUTO", "WEBP", "AVIF"]}
              loading="eager"
              width={1920} // Define width
              height={1080} // Define height
            />
            <div className="entry-page__logo">
              <div className="entry-page__logo__wrapper">
                <StaticImage
                  src="../../images/rawColorLogoR.png"
                  alt="Raw Color logo"
                  placeholder="blurred"
                  layout="fullWidth"
                  loading="eager"
                  width={300} // Define width
                  height={300} // Define height
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ zIndex: 2 }} className="entry-page__content">
        <div ref={textRef} className="entry-page__content__inner">
          <h1>{text}</h1>
        </div>
      </div>
    </div>
  );
};

export default EntryPage;
