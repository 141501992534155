import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { StaticImage } from "gatsby-plugin-image";
import VolumeDial from "../volume-dial";
import PlayerButtons from "../buttons";
import ShowReelContext from "../../../context/showreelContext";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./video.scss";
import { colorizer } from "../../../lib/colorizer";
import staticVideo from "../../../images/homepage/static.mp4";
gsap.registerPlugin(ScrollTrigger);

const VideoPlayer = ({ url, controls = true, backgroundTV = false }) => {
  const playerRef = useRef(null);
  const waterMarkRef = useRef(null);
  const staticRef = useRef(null);
  const playerContainerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [playerVolume, setPlayerVolume] = useState(0.8);

  useEffect(() => {
    gsap.fromTo(
      playerRef.current,
      { filter: "brightness(10%)" },
      {
        filter: "brightness(130%)",
        duration: 2,
        ease: "power1.out",
        scrollTrigger: {
          trigger: playerRef.current,
          scrub: true,
          start: "top bottom",
          end: "center center",
        },
      }
    );

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  useEffect(() => {
    const iframe = playerContainerRef.current.querySelector("iframe");
    if (iframe) {
      if(playing && !isFullScreen) {
        gsap.to(iframe, {
          scale: 1.5,
          duration: 2,
          ease: "power1.out",
          opacity: 1,
        });
      }
      if (!playing) {
        gsap.to(iframe, {
          duration: 2,
          ease: "power1.out",
          opacity: 0,
          scale: 0,
        });
      }
      if (isFullScreen) {
        gsap.to(iframe, {
          scale: 1,
          duration: 2,
          ease: "power1.out",
          opacity: 1,
        });
      }
    }
    if (playing || isFullScreen) {
      gsap.to(waterMarkRef.current, {
        opacity: 0,
        duration: 1,
        ease: "power1.out",
      });
      gsap.to(staticRef.current, {
        opacity: 0,
        duration: 2,
        ease: "power1.out",
      });
    } else {
      gsap.to(waterMarkRef.current, {
        opacity: 1,
        duration: 1,
        ease: "power1.out",
      });
      gsap.to(staticRef.current, {
        opacity: 0.4,
        duration: 2,
        ease: "power1.out",
      });
    }
  }, [playing, isFullScreen]);

  const handlePlayPause = () => {
    if (!playing) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  };

  const toggleFullScreen = () => {
    const playerContainer = playerContainerRef.current;
    if (!document.fullscreenElement) {
      setIsFullScreen(true);
      if (playerContainer.requestFullscreen) {
        playerContainer.requestFullscreen();
      } else if (playerContainer.mozRequestFullScreen) {
        /* Firefox */
        playerContainer.mozRequestFullScreen();
      } else if (playerContainer.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        playerContainer.webkitRequestFullscreen();
      } else if (playerContainer.msRequestFullscreen) {
        /* IE/Edge */
        playerContainer.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setIsFullScreen(false))
          .catch((e) => console.error(e));
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreen = !!document.fullscreenElement;
      setIsFullScreen(isFullScreen);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    // Make sure to clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  return (
    <div ref={playerRef} className="rc-video">
      <ShowReelContext.Provider
        value={{
          playerVolume,
          setPlayerVolume,
        }}
      >
        <div className="rc-video__logo">
          <StaticImage
            src="../../../images/Logo.png"
            alt="tv-overlay"
            layout="fullWidth"
            quality={60}
            formats={["AUTO", "WEBP", "AVIF"]}
          />
        </div>
        <div className="rc-video__background">
          <StaticImage
            src="../../../images/homepage/seventiesTV.png"
            alt="tv-overlay"
            layout="fullWidth"
            quality={60}
            formats={["AUTO", "WEBP", "AVIF"]}
          />
        </div>
        <div ref={playerContainerRef} className="rc-video__player">
          <ReactPlayer
            url={url}
            playing={playing}
            controls={controls}
            width="100%"
            height="100%"
            volume={playerVolume}
            onEnded={() => setPlaying(false)}
            config={{
              youtube: {
                playerVars: { showinfo: 1, modestbranding: 1, color: "white" },
              },
            }}
          />
          <div className="rc-video__watermark" ref={waterMarkRef}>
            <StaticImage
              src="../../../images/rawColorProfile.png"
              alt="raw color logo"
              width={80}
            />

            <h3>{colorizer("PRESS PLAY")}</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 22 22"
            >
              <path
                fill="var(--white)"
                d="M10 2h2v1h1v1h1v1h1v1h1v1h1v1h1v1h1v1h1v2h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-2v-5H2V7h8V2m2 4v3H4v4h8v3h1v-1h1v-1h1v-1h1v-1h1v-2h-1V9h-1V8h-1V7h-1V6h-1Z"
              />
            </svg>
          </div>
        </div>
        <span className="rc-video__overlay"></span>
        <span className="rc-video__overlay--right"></span>
        <VolumeDial />
        <div className="rc-video__buttons">
          <div
            onClick={() => handlePlayPause()}
            className="rc-video__buttons__button"
          >
            <PlayerButtons pressed={playing} />
            <p>Play/Pause</p>
          </div>
          <div
            onClick={() => toggleFullScreen()}
            className="rc-video__buttons__button"
          >
            <PlayerButtons />
            <p>Full-screen</p>
          </div>
        </div>
        <a
          className="rc-video__static"
          href="https://www.vecteezy.com/video/1614721-4k-tv-static"
          ref={staticRef}
        >
          <video playsInline autoPlay muted loop>
            <source src={staticVideo} type="video/mp4" />
          </video>
        </a>
      </ShowReelContext.Provider>
    </div>
  );
};

export default VideoPlayer;
