import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./art.scss";
gsap.registerPlugin(ScrollTrigger);

const ArtSection = () => {

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const letters = [...container.children];

    gsap.fromTo(
      letters,
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.25,
        stagger: 0.25,
        scrollTrigger: {
          // markers: true,
          pin: true,
          trigger: container,
          scrub: 1,
          start: "top center",
          end: () => "+=" + 1300,
        },
      }
    );
  }, []);


  return (
    <div className="art-section">
      <div className="art-section__inner">
        <div ref={containerRef} className="art-section__container">
          <h1>
            <span>A</span>
            <span>R</span>
            <span>T</span>
          </h1>
          <h1>
            <span>B</span>
            <span>E</span>
            <span>F</span>
            <span>O</span>
            <span>R</span>
            <span>E</span>
          </h1>
          <h1>
            <span>A</span>
            <span>L</span>
            <span>L</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ArtSection;
