import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./headline.scss";
gsap.registerPlugin(ScrollTrigger);

const Headline = ({ text, triggerId }) => {
  const headlineRef = useRef(null);
  const colors = ["#a2001c", "#f2b323", "#005239", "#15307e", "#96317f"];

  useEffect(() => {
    const headlineChildren = [...headlineRef.current.children];
    const headlineArray = headlineChildren.slice(0, -1);
    headlineArray.forEach((ele, index) => {
      gsap.fromTo(
        ele,
        {
          y: 0,
          x: 0,
          duration: 1,
          ease: "elastic.out(1, 0.3",
        },
        {
          y: (index + 1) * 4,
          x: (index + 1) * 4,
          duration: 2,
          ease: "elastic.out(1, 0.3",
          scrollTrigger: {
            trigger: `#${triggerId}`,
            toggleActions: "play none none reverse",
            start: "top top+=300",
            end: "bottom top",
          },
        }
      );
    })
  }, []);

  return (
    <div ref={headlineRef} className="rc-headline">
      {colors.map((color, index) => (
        <span
        key={color+index}
          style={{
            zIndex: 6 - (index + 1),
            color: color,
          }}
        >
          {text}
        </span>
      ))}
      <p>{text}</p>
    </div>
  );
};

export default Headline;
